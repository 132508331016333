.main-wrap .right .top,
.btnstyle1,
.button-new1.blue,
.button-new1.grey:hover,
.notification-filter .right-filter .btn.filter,
.main-wrap .right .top,
.ViewType,
.widget-wrap .mid-sec .inner .chat-sec .chat .buttons .btn,
.dropdown-s1 .dropdown-menu .dropdown-item:hover, 
.dropdown-s1 .dropdown-menu .dropdown-item.active,
.loginUser .dropdown-menu,
.btngroup2 .grad-btn-1,
.action-icons-list .dropdown-menu {    
    background: var(--primary-color, '#10ad08');  /* primary-color */
}

.main-wrap .right .top ul.menu li a:hover, 
.main-wrap .right .top ul.menu li.active a,
.action-icons-list .dropdown-menu a:hover {    
    background: var(--secondary-color, '#0f8709'); /* secondary-color */
}

.clients-accordion-main .card .card-header button, 
.main-wrap .left .navbar-nav a.nav-link.active, 
.main-wrap .left .navbar-nav a.nav-link:hover, 
.table-style-new1 thead th,
.notifications-wrap .notifwrap-block .nwb-head h3,
.widget-wrap .mid-sec .inner .chat-sec .chat .title,
.title-top-right > ul > li > a:hover,
.top-slider .cell .title,
.main-wrap .right .top .cell-two .noti {
    color: var(--primary-color, '#10ad08');
}

.main-wrap .left .navbar-nav a.nav-link.active svg path, 
.main-wrap .left .navbar-nav a.nav-link.show svg path, 
.main-wrap .left .navbar-nav a.nav-link:hover svg path,
.accordion-actions .icons a:hover svg path,
.widget-wrap .widget-footer .top .send svg path,
.title-top-right > ul > li > a:hover svg path,
.clients-accordion-main .card .card-header button svg path {
    fill: var(--primary-color, '#10ad08');
}

.notification-filter .right-filter .btn:hover {
    background: var(--secondary-color, '#0f8709');
}
.notification-filter .right-filter .btn:hover {
    background: var(--secondary-color, '#0f8709');
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-right .nafilebtn-download {
    background-color: var(--primary-color, '#10ad08');
    border: 1px solid var(--primary-color, '#10ad08');
}

.accordion-actions .icons a:hover,
.action-icons-list .dropdown.with-dropdown button:hover {
    border: 1px solid var(--primary-color, '#10ad08');
}
.action-icons-list .dropdown-menu a {
    border-bottom: 1px solid var(--secondary-color, '#0f8709');
}
.action-icons-list .dropdown-menu::before {
    border-bottom: solid 10px var(--primary-color, '#10ad08');
}
.action-icons-list .dropdown.with-dropdown button:hover svg,
.table-style-new1 td a:hover,
.action-icons-list li a:hover svg {
    color: var(--primary-color, '#10ad08');
}

.form-check-input:checked {
    background-color: var(--primary-color, '#10ad08');
    border-color: var(--primary-color, '#10ad08');
}
