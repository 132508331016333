@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-UltraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Hero New';
    src: url('../webfonts/hero/Hero-New-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    font-family: 'Hero New', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: #000000;
    background-color: #ffffff;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.wow {
    visibility: hidden;
}
a,
button,
.btn {
    -webkit-transition: 0.5s;
       -moz-transition: 0.5s;
        -ms-transition: 0.5s;
         -o-transition: 0.5s;
            transition: 0.5s;
}
a {
    text-decoration: none;
}
p:last-child {
    margin-bottom: 0;
}
.owl-carousel .owl-item img {
    width: auto;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #989898 !important;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #989898 !important;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #989898 !important;
}
:-moz-placeholder { /* Firefox 18- */
    color: #989898 !important;
}
  


/* COMMON STYLE :START */

/* ---- btn-style-1 :start ---- */
.btn-style-1 {
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    background: rgb(70,151,185);
    background: -moz-linear-gradient(90deg, rgba(70,151,185,1) 0%, rgba(133,186,89,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(70,151,185,1) 0%, rgba(133,186,89,1) 100%);
    background: linear-gradient(90deg, rgba(70,151,185,1) 0%, rgba(133,186,89,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4697b9",endColorstr="#85ba59",GradientType=1);
    border: 0;
    border-radius: 25px;
    padding: 13px 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: 0.5s;
       -moz-transition: 0.5s;
        -ms-transition: 0.5s;
         -o-transition: 0.5s;
            transition: 0.5s;

}
.btn-style-1:hover {
    color: #ffffff;
    background: rgb(70,151,185);
    background: -moz-linear-gradient(-90deg, rgba(70,151,185,1) 0%, rgba(133,186,89,1) 100%);
    background: -webkit-linear-gradient(-90deg, rgba(70,151,185,1) 0%, rgba(133,186,89,1) 100%);
    background: linear-gradient(-90deg, rgba(70,151,185,1) 0%, rgba(133,186,89,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4697b9",endColorstr="#85ba59",GradientType=1);
}
/* ---- btn-style-1 :end ---- */

/* ---- form-style-1 :start ---- */
.form-style-1 {
    width: 100%;
    height: auto;
}
.form-style-1 .input-group {
    background-color: #efefef;
    border-radius: 25px;
    overflow: hidden;
}
.form-style-1 .input-group .input-group-text {
    font-size: 16px;
    color: #4697b9;
    background-color: transparent;
    border: 0;
    padding: 0px 15px 0px 24px;
}
.form-style-1 .input-group .form-control {
    font-size: 16px;
    color: #000000;
    background-color: transparent;
    border: 0;
    padding: 13px 24px 13px 0px;
}
.form-style-1 .input-group .form-control:focus {
    box-shadow: none;
}
/* ---- form-style-1 :end ---- */



/* classy nav start */
.classy-nav-container {
    /* background-color: #cc0000; */
    padding: 0 0;
    z-index: 11; }
  
  .classy-nav-container .classy-navbar {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0;
    -webkit-justify-content: flex-end !important;
    justify-content: flex-end !important; }
  
  .classy-nav-container .classy-navbar .classynav ul li a {
      font-size: 14px;
      color: #000;
    padding: 14px 12px 14px 12px;
    margin: 0 0 0 0;
    font-weight: 400;
    position: relative; 
    display: flex;
    align-items: center;
}
.classy-nav-container .classy-navbar .classynav ul li a img {
    margin-right: 15px;
}
.classy-nav-container .classy-navbar .classynav ul li a svg {
    margin-right: 15px;
    width: 18px;
}
.classy-nav-container .classy-navbar .classynav ul li a:hover {
    background: none;
    color: #1ba0d8 !important;
 }
.classy-nav-container .classy-navbar .classynav ul li a:hover svg path {
    fill: #1ba0d8 !important;
 }
  
  /* .classy-nav-container .classy-navbar .classynav ul li:hover>a {
      background: #000;
      color: #FFF !important;
  } */
  /* .cn-dropdown-item:hover > a {
      background: #A30000 !important;
  } */
  .cn-dropdown-item .dropdown a::before {
    display: none; }
  
  .classynav ul li a:hover .classynav ul li.has-down::after {
    color: #cc0000 !important; }
  
  .classy-nav-container .classy-navbar .classynav ul li.cn-dropdown-item ul li a,
  .classy-nav-container .classy-navbar .classynav ul li.megamenu-item ul li a {
    font-size: 14px;
    color: #000;
    padding: 10px 0 !important;
    /* border-bottom: 1px solid #fff; */
    width: 100%;
    height: auto;
    line-height: inherit; }
  
  /* .classy-nav-container .classy-navbar .classynav ul li.cn-dropdown-item ul li:last-child a {
    border-bottom: 0; } */
  
  .breakpoint-off .classynav ul li .dropdown li.sub2 > a {
    border-bottom: 0 !important; }
  
  .breakpoint-off .classynav ul li .dropdown li.sub2 ul.dropdown li:last-child a {
    border-bottom: 0 !important; }
  
  .classynav ul li.cn-dropdown-item ul li a:hover {
    background: #000;
    color: #fff !important; }
  
  .classynav ul li.active a {
    position: relative;
    z-index: 1; }
  
  .classynav ul li.active a::before {
    width: 80%;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 10%;
    z-index: 1;
    content: ''; }
  
  .classynav ul li.active a::before {
    display: none; }
  
  .classynav ul li.active a::before {
    display: none; }
  
  .classynav ul li.has-down > a::after,
  .classynav ul li.has-down.active > a::after,
  .classynav ul li.megamenu-item > a::after {
    color: #fff; }
  
  .classynav #nav {
    margin: 0;
    padding: 0;
    display: table; }
  
  .breakpoint-off .classynav ul li .dropdown {
    padding-top: 0;
    padding-bottom: 0;
    background: #703696 !important;
    /* width: 200px; */
    left: 0; }
  
  .breakpoint-off .classynav ul li .dropdown li:hover .dropdown {
    top: 0; }
  
  .classynav ul li.has-down > a::after {
    font-size: 10px; }
  
  .classynav ul li.has-down.sub2 {
    position: relative; }
  
  .classynav ul li.has-down.sub2 > a::after {
    display: none; }
  
  .classynav ul li.has-down.sub2 > a::before {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    -moz-transform: translateY(-50%) rotate(-90deg);
    -ms-transform: translateY(-50%) rotate(-90deg);
    -o-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg);
    font-family: 'classyfonts';
    content: "\e900";
    font-size: 10px; }
  
  .classynav ul li.cn-dropdown-item ul li a {
    margin-left: 0 !important; }
  
  .classy-menu {
    width: 100%; }
  
  /* classy nav end */



/* COMMON STYLE :END */
.login-section {
    width: 100%;
    height: 100vh;
    min-height: 600px;
}


.login-section .logsec-left {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.login-section .logsec-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.login-section .logsec-right {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 100px 30px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.login-section .logsec-right .lsr-inner {
    width: 100%;
    max-width: 354px;
    height: auto;
}


.login-section .logsec-right .lsr-logo {
    padding: 0px 12px;
    margin-bottom: 60px;
    text-align: center;
}
.login-section .logsec-right .lsr-logo img {
    max-width: 100%;
    height: auto;
}


.login-section .logsec-right .poweredby {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.login-section .logsec-right .poweredby p {
    font-size: 14px;
    font-weight: 400;
    color: #2b2a29;
    margin: 0;
}
.login-section .logsec-right .poweredby img {
    max-width: 100%;
    height: auto;
}


.login-section .logsec-right .lsr-logo-2 {
    position: absolute;
    top: 15px;
    right: 20px;
}
.login-section .logsec-right .lsr-logo-2 img {
    max-width: 100%;
    height: auto;
}

/* main-wrap: start */

.main-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100vh;
    overflow-y: auto;
}
.main-wrap .left {
    width: 230px;
    /* background-color: blue; */
    height: 500px;
    border-right: 1px solid #d0d0d0;
    height: 100vh;
    transition: 0.3s;
    
    position: fixed;
}
.main-wrap .right {
    flex: 1;
    /* background-color: red; */
    height: 100vh;
    padding-left: 230px;
    width: calc(100% - 230px);
    transition: 0.3s;
}
.main-wrap .right .top {
    height: 54px;
    width: 100%;
    background: #1ba0d8;
    padding: 0 20px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-wrap .right .top .search {
    padding-top: 0;
    position: relative;
    float: left;
}
.main-wrap .right .top .search input {
    width: 190px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 15px;
    background: none;
    padding: 0 15px 0 47px;
    font-size: 13px;
    color: #fff;
    outline: none !important;
}
.main-wrap .right .top .search input::placeholder {
    color: #fff !important;
    opacity: 1;
}
.main-wrap .right .top .search img {
    position: absolute;
    top: 8px;
    left: 17px;
}
.main-wrap .right .top ul.menu {
    float: left;
    list-style: none;
    padding: 0;
    margin: 0 0 0 25px;
}
.main-wrap .right .top ul.menu li {
    float: left;
    margin: 0 1px;
}
.main-wrap .right .top ul.menu li a {
    padding: 3px 20px;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    border-radius: 13px;
}
.main-wrap .right .top ul.menu li a:hover,
.main-wrap .right .top ul.menu li.active a {
    background: #1595cb;
}
.main-wrap .right .top .cell-one {
    display: flex;
    align-items: center;
}
.main-wrap .right .top .cell-two .noti {
    position: relative;
}
.main-wrap .right .top .cell-two .noti svg {
    width: 20px;
}
.main-wrap .right .top .cell-two .noti svg path {
    fill: #fff;
}
.main-wrap .right .top .cell-two .noti.active::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #91ff36;
    position: absolute;
    top: 0;
    right: 0;
    border: 2px solid #1ba0d8;
}
.main-wrap .right .top .cell-two .noti .count {
    position: absolute;
    top: -6px;
    right: -5px;
    background: #fff;
    font-size: 12px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}
.main-wrap .right .top .cell-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
}
.main-wrap .right .top .cell-two .dropdown .dropdown-toggle {
    border: 0;
    border-radius: 0;
    padding: 0;
    background: none;
    outline: none !important;
    box-shadow: none;
}
.main-wrap .right .top .cell-two .dropdown img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}
.main-wrap .right .top .cell-two .dropdown .dropdown-menu {
    border-radius: 0;
    background: #1fade9;
    border: 0;
    margin-top: 7px !important;
    padding: 10px 0;
    font-size: 14px;
}
.main-wrap .right .top .cell-two ul.dropdown-menu li a {
    color: #fff !important;    
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.main-wrap .right .top .cell-two ul.dropdown-menu li a:hover {
    color: #000 !important;    
    background: #fff;
}


















.main-wrap .right .inner {
    padding: 30px 20px 10px 20px;
}
.main-wrap .right .inner h1 {
    font-size: 22px;
    color: #2b2a29;
    font-weight: 400;
    margin-bottom: 25px;
}
.main-wrap .right .inner h2 {
    font-size: 20px;
    color: #2b2a29;
    font-weight: 400;
    margin-bottom: 25px;
}
.top-slider {
    margin-bottom: 45px;
}
.top-slider li {
    position: relative;
    cursor: pointer;
}
.top-slider .topCloseIcon {
    position: absolute;
    top: 0;
    right: 30px;
    display: none;
}
.top-slider li:hover .topCloseIcon {
    display: block;
}
.top-slider .topCloseIcon:hover {
    
}
.top-slider .cell {
    display: flex;
    flex-direction: row;
    /* width: 220px; */
    /* background: yellow; */
    align-items: flex-start;
    gap: 20px;
    float: left;
}
.top-slider .cell:hover {
    text-decoration: none !important;
}
.top-slider .cell .title {
    display: block;
    color: #1ba0d8;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}
.top-slider .cell p {
    font-size: 20px;
    color: #000;
    margin: 5px 0 0 0;
}
.top-slider .cell .name {
    font-size: 12px;
    color: #000;
    display: block;
    margin: 4px 0 0 0;
}
.top-slider .cell .time-date {
    font-size: 10px;
    color: #9a9a9a;
    display: block;
    margin: 10px 0 0 0;
}
.top-slider .owl-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 54px;
    position: absolute;
    top: -51px;
    right: 0;
}
.top-slider .owl-next,
.top-slider .owl-prev {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    /* border: 1px solid #cecece !important; */
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

}

.top-slider .owl-next svg path,
.top-slider .owl-prev svg path {
    fill: #cbcbcb;
}
.top-slider .owl-next:hover svg path,
.top-slider .owl-prev:hover svg path {
    fill: #79b349;
}





/* Clients Section: Start */
.clients-sec .clients-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.clients-sec .clients-top h2 {
    margin: 0 !important;
}
.clients-sec .clients-top .filter-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.clients-sec .clients-top .filter-cell .nice-select {
    height: 30px;
    line-height: 28px;
    border-radius: 15px;
    width: 170px;
    font-size: 13px;
}
.clients-sec .clients-top .filter-cell .nice-select .list {
    font-size: 13px;
    width: 100%;
}
.clients-sec .clients-top .filter-cell .nice-select .list li.option {
    min-height: 30px;
    line-height: 30px;
}
.clients-sec .clients-top .filter-cell .nice-select .list li.option:hover {
    background: #1ba0d8 !important;
}
.clients-sec .clients-top .filter-cell .nice-select .list li.focus {
    color: #1ba0d8;
}
.clients-sec .clients-top .filter-cell .nice-select .list li.focus:hover {
    color: #fff;
}
.clients-sec .clients-top .search {
    /* padding-top: 12px; */
    position: relative;
    float: left;
}
.clients-sec .clients-top .search input {
    width: 190px;
    height: 30px;
    border: 1px solid #e2e2e2;
    border-radius: 15px;
    background: none;
    padding: 0 15px 0 42px;
    font-size: 13px;
    color: #000;
    outline: none !important;
}
.clients-sec .clients-top .search input::placeholder {
    color: #bbbcc2 !important;
    opacity: 1;
}
.clients-sec .clients-top .search img {
    position: absolute;
    top: 9px;
    left: 17px;
}
/* Clients Section: End */















/* menu colaps - Start */
.open-close {
    font-size: 14px;
    padding: 0;
}
.main-wrap .left.off {
    width: 55px;
}
.main-wrap .left.off .classy-nav-container .classy-navbar .classynav ul li a {
    font-size: 0;
}
.main-wrap .left.off .breakpoint-on .dd-trigger {
    display: none;
}
.main-wrap .left.off .breakpoint-on .dd-trigger {
    display: none;
}
.main-wrap .left.off .classy-nav-container .classy-navbar .classynav ul li a img {
    margin-right: 4px;
}
.main-wrap .right.off {
    padding-left: 55px;
    width: calc(100% - 55px);
}
.main-wrap .left.off .s-logo {
    display: none !important;
}
/* menu colaps - end */
.logo-menu {
    padding: 8px 15px 0 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 30px 0;
}
.logo-menu .open-close {
    border: 0;
    background: none;
}
/* Clients accordion: Start */
.clients-accordion-main {
    margin: 20px 0 0 0;
}
.clients-accordion-main .accordion-item {
    margin: 0 0 20px 0;
    border: 0;
    outline: none;
}
.clients-accordion-main .accordion-header {
    background-color: #f1f5f6;
    border-radius: 5px;
}
.clients-accordion-main .accordion-button {
    box-shadow: none !important;
    padding: 20px 15px;
    background-color: #f1f5f6;
    font-size: 16px;
    font-weight: 400;
    color: #1ba0d8;
    border-radius: 5px !important;
}
.clients-accordion-main .accordion-button[aria-expanded="true"] {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.clients-accordion-main .accordion-button::after {
    width: 14px;
    height: 6px;
    background-size: 14px;
    background-position: center;
}
.clients-accordion-main .accordion-body {
    padding: 15px;
    background-color: #f1f5f6;
    position: relative;
}
/* Clients accordion: End */
/* clients tab main: Start */
.clients-tab-main .nav-link {
    font-size: 14px;
    font-weight: 400;
    color: #2b2a29;
    /* margin-bottom: 0; */
    padding: 8px 18px;
    border: 0;
    border-radius: 0;
    background: none;
}
.clients-tab-main .nav-link.active {
    border-bottom: 2px solid #1ba0d8;
    background: none;
}
.clients-tab-inner {
    background: #fff;
    padding: 25px;
    border-radius: 15px;
    margin: 15px 0 0 0;
}
.clients-tab-inner h4 {
    font-size: 14px;
    color: #1ba0d8;
    font-weight: 600;
}
.clients-tab-inner .accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
}
.update-data {
    background-image: -moz-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border: 0;
    height: 30px;
    border-radius: 18px;
    padding: 0 15px;
    position: absolute;
    right: 15px;
    top: 15px;
}
.update-data:hover {
    background-image: -moz-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
}
/* clients tab main: End */

/* Inner accordion one: Start */
.client-accordion-inner1-main {
    margin: 10px 0 0 0;
}
.client-accordion-inner1-main .accordion-item {
    margin: 0;
}
.client-accordion-inner1-main .accordion-button {
    border-radius: 0 !important;
    font-size: 14px;
    font-weight: 400;
    color: #838591;
    padding-left: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.client-accordion-inner1-main .accordion-item:nth-of-type(even) .accordion-header {
    background-color: #fff !important;
}
.client-accordion-inner1-main .accordion-item:nth-of-type(even) .accordion-button {
    background-color: #fff !important;
}
.client-accordion-inner1-main .accordion-item:nth-of-type(even) .accordion-body {
    background-color: #fff !important;
}
.client-accordion-inner1-main .accordion-button::after {
    position: absolute;
    top: 25px;
    left: 15px;
}
.accordion-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}
.accordion-actions span {
    font-size: 14px;
    color: #838591;
    font-weight: 400;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.accordion-actions .infoIcn {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid red;
    background: #edf0f9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordion-actions .infoIcn svg {
    height: 12px;
}
.accordion-actions .infoIcn svg path {
    fill: red;
}
.accordion-actions .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.accordion-actions .icons a {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #e1e3ea;
    background: #edf0f9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordion-actions .icons a:hover {
    border: 1px solid #1ea1d9;
}
.accordion-actions .icons a svg {
    width: 12px;
}
.accordion-actions .icons a svg path {
    fill: #7a7f87;
}
.accordion-actions .icons a:hover svg path {
    fill: #1ea1d9;
}
.accordion-actions .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.accordion-actions .buttons a {
    padding: 0 12px;
    height: 26px;
    border-radius: 13px;
    background: #79b349;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}
.accordion-actions .buttons a img {
    margin: 0 6px 0 0;
}
.accordion-actions .buttons .export {
    background: #1ba0d8;
}
.accordion-actions .buttons a:hover {
    background: #545454;
    text-decoration: none;
}
.accordion-actions .buttons .export.ask {
    background-image: -moz-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
}
.accordion-actions .buttons .export.ask:hover {
    background-image: -moz-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
}
/* Inner accordion one: End */
/* Inner accordion one > One: Start */
.client-accordion-inner1-main.inner-accordion {
    /* background: red; */
    margin-top: 0;
}
.client-accordion-inner1-main.inner-accordion .accordion-header {
    background: #f7fafb !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-button {
    background: #f7fafb !important;
    padding-left: 60px;
}
.client-accordion-inner1-main.inner-accordion .accordion-item:nth-of-type(even) .accordion-header {
    background: #f7fafb !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-item:nth-of-type(even) .accordion-button {
    background: #f7fafb !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-body {
    background: #f7fafb !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-item:nth-of-type(even) .accordion-body {
    background: #f7fafb !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-button::after {
    left: 36px;
}
/* Inner accordion one > One: End */
/* Inner accordion one > One > One: Start */
.client-accordion-inner1-main.inner-accordion.in1 .accordion-header {
    background: #f0f7f9 !important;
}
.client-accordion-inner1-main.inner-accordion.in1 .accordion-button {
    padding-left: 80px;
    background: #f0f7f9 !important;
}
.client-accordion-inner1-main.inner-accordion.in1 .accordion-item:nth-of-type(even) .accordion-header {
    background: #f0f7f9 !important;
}
.client-accordion-inner1-main.inner-accordion.in1 .accordion-item:nth-of-type(even) .accordion-button {
    background: #f0f7f9 !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-body {
    background: #f0f7f9 !important;
}
.client-accordion-inner1-main.inner-accordion .accordion-item:nth-of-type(even) .accordion-body {
    background: #f0f7f9 !important;
}
.client-accordion-inner1-main.inner-accordion.in1 .accordion-button::after {
    display: none;
}

/* Inner accordion one > One > One: End */

/* Resource information Modal : Start */
.resource-information .modal-header {
    padding: 0 0 15px 0;
}
.resource-information .modal-header .modal-title  {
    font-size: 18px;
}
.resource-information span.label  {
    font-size: 12px;
    display: block;
    margin: 0 0 5px 0;
}
.resource-information .modal-inner  {
    padding-top: 15px;
    padding-bottom: 15px;
}
.resource-information .modal-inner .form-row {
    padding-bottom: 13px;
}
.resource-information .modal-inner .form-row:last-child {
    padding-bottom: 0;
}
.resource-information .modal-inner input,
.resource-information .modal-inner textarea {
    height: 36px;
    border-radius: 18px;
    border: 1px solid #b1b1b1;
    width: 100%;
    padding: 0 15px;
    font-size: 12px;
    outline: none;
}
.resource-information .modal-inner textarea {
    height: 100px;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.resource-information .modal-inner .nice-select {
    padding: 0 15px;
    border-radius: 18px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
}
.resource-information .modal-inner .nice-select .list {
    width: 100%;
}
.resource-information .modal-inner .nice-select .list li.focus {
    color: #000;
}
.resource-information .modal-inner .nice-select .list li:hover {
    color: #fff;
    background: #1ba0d8 !important;
}
.resource-information .modal-footer {
    padding-bottom: 0;
    padding-right: 0;
}
.resource-information .modal-footer .btn-1 {
    padding-left: 25px;
    padding-right: 25px;
    height: 30px;
    border-radius: 15px;
    font-size: 12px;
    color: #fff;
    margin: 0;
}
/* Resource information Modal : End */

/* Common class */
.btn-bg-1 {
    background-image: -moz-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
}
.btn-bg-1:hover {
    background-image: -moz-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
}
.btn-bg-2 {
    background: #949494;
}
.btn-bg-2:hover {
    background: #333232;    
}
/* Common class */

/* Widget: Start */
.widget-button {
    background-image: linear-gradient(
        55deg,
        hsl(198deg 62% 42%) 0%,
        hsl(191deg 100% 35%) 14%,
        hsl(186deg 100% 34%) 29%,
        hsl(180deg 100% 32%) 44%,
        hsl(172deg 100% 34%) 58%,
        hsl(157deg 56% 45%) 73%,
        hsl(130deg 38% 55%) 87%,
        hsl(93deg 41% 54%) 100%
      );
    /* background-color: rgb(72, 188, 110); */
    width: 64px;
    height: 64px;
    border: 1px solid #1289bb;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;
}
.widget-button img {
    transition: 0.9s;
}
.widget-button:hover img {
    transform: rotate(360deg);
}
.widget-wrap {
    width: 700px;
    height: 0;
    padding: 0;
    position: fixed;
    right: 20px;
    bottom: 100px;
    /* background: rgb(72, 188, 110); */
    border-radius: 30px;
    background-image: linear-gradient(
    30deg,
    hsl(198deg 62% 42%) 0%,
    hsl(191deg 100% 35%) 14%,
    hsl(186deg 100% 34%) 29%,
    hsl(180deg 100% 32%) 44%,
    hsl(172deg 100% 34%) 58%,
    hsl(157deg 56% 45%) 72%,
    hsl(130deg 38% 55%) 86%,
    hsl(93deg 41% 54%) 100%
    );
    overflow: hidden;
    transition: 0.3s;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* remove it */
    /* height: calc(100vh - 170px);
    padding: 25px; */
}
.widget-wrap.full-w {
    width: calc(100% - 40px);
}
.widget-wrap.open {
    height: calc(100vh - 170px);
    padding: 25px;
    z-index: 9999;
}
.widget-wrap .widget-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
}
.widget-wrap .widget-head .left {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
}
.widget-wrap .widget-head .left span {
    font-size: 20px;
    color: #fff;
}
.widget-wrap .widget-head .right .btns {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
}
.widget-wrap .widget-head .right .btns .btn {
    padding: 0;
    border: 0;
}
/* @media (min-width: 768px) {
    .widget-wrap .widget-head .right .btns .btn.minimize-maximize {
        display: none;
    }
} */
@media (max-width: 767px) {
    .widget-wrap.open.full-w {
        height: calc(100vh - 0px);
        bottom: 0;
        width: 100%;
        left: 0;
        border-radius: 0;
    }
}

.widget-wrap .widget-head .right .btns .btn svg path {
    fill: #fff;
}
.widget-wrap .widget-head .right .btns .btn:hover svg path {
    fill: #4dff35;
}
.widget-wrap .mid-sec {
    /* background: red; */
    height: 100%;
    overflow: hidden;

    /* display: flex;
    align-items: flex-end; */
}
.widget-wrap .mid-sec .inner {
    height: 100%;
    width: 100%;
    /* background-color: yellow; */
}
.widget-wrap .mid-sec .inner .chat-sec {
    /* background-color: red; */
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: calc(100% - 65px);
    margin-bottom: 15px;
}
.widget-wrap .mid-sec .inner .chat-sec .chat-image {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.widget-wrap .mid-sec .inner .chat-sec .chat {
    flex: 1;
    background: #fff;
    padding: 15px;
    border-radius: 24px;
    border-top-left-radius: 4px;
}
.widget-wrap .mid-sec .inner .chat-sec .chat .title {
    font-size: 16px;
    color: #1ba0d8;
    margin: 0 0 10px 0;
    display: block;
}
.widget-wrap .mid-sec .inner .chat-sec .chat .buttons {
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: flex-start;
    flex-wrap: wrap;
}
.widget-wrap .mid-sec .inner .chat-sec .chat .buttons .btn {
    font-size: 14px;
    font-weight: 400;
    background: #1ba0d8 !important;
    padding: 0;
    color: #fff;
    padding: 5px 10px;
    border-radius: 16px;
}
.widget-wrap .mid-sec .inner .chat-sec.user {
    margin-left: 65px;
    flex-direction: row-reverse;
}
.widget-wrap .mid-sec .inner .chat-sec.user .chat {
    border-top-right-radius: 4px;
    border-top-left-radius: 24px;
}
.widget-wrap .mid-sec .inner .chat-sec.user .chat .title {
    text-align: right;
}
.widget-wrap .mid-sec .inner .chat-sec.user .chat .buttons {
    justify-content: flex-end;
}
.widget-wrap .mid-sec .inner .graph-1 {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    position: relative;
}
.widget-wrap .mid-sec .inner .graph-1 img {
    max-width: 100%;
    width: 100%;
}



.ViewType {
    padding: 0 12px;
    height: 30px;
    border-radius: 15px;
    background: #1ba0d8;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}
.ViewType:hover {
    color: #fff;
    background: #545454;
    text-decoration: none;
}

.read-unread {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}
.read-unread .left-text {
    font-size: 12px;
    color: #000000;
}
.read-unread .right-text {
    font-size: 13px;
    color: #70b03b;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
}
.read-unread .right-text img {
    position: relative;
    top: -1px;
}
.read-unread .right-text:hover {
    color: #000;
}
.notification-filter {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
}
.notification-filter .search {
    /* padding-top: 12px; */
    position: relative;
    float: left;
}
.notification-filter .search input {
    background: #fff;
    width: 190px;
    height: 34px;
    border: 1px solid #e2e2e2;
    border-radius: 17px;
    padding: 0 15px 0 42px;
    font-size: 13px;
    color: #000;
    outline: none !important;
}
.notification-filter .search input::placeholder {
    color: #bbbcc2 !important;
    opacity: 1;
}
.notification-filter .search img {
    position: absolute;
    top: 11px;
    left: 17px;
}
.notification-filter .right-filter {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.notification-filter .right-filter {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}
.notification-filter .right-filter .dateF {
    background: #fff;
    width: 190px;
    height: 34px;
    border: 1px solid #e2e2e2;
    border-radius: 17px;
    padding: 0 15px 0 15px;
    font-size: 13px;
    color: #000;
    outline: none !important;
}
.notification-filter .right-filter span.to {
    font-size: 14px;
    color: #000;
}
.notification-filter .right-filter .btn {
    height: 34px;
    color: #fff;
    border-radius: 17px;
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
}
.notification-filter .right-filter .btn.clear {
    background: #949494;
}
.notification-filter .right-filter .btn.filter {
    background: #1ba0d9;
}
.notification-filter .right-filter .btn:hover {
    background: #242222;
}
.notification-filter .right-filter .cell {
    position: relative;
}
.notification-filter .right-filter .cell img {
    position: absolute;
    right: 15px;
    top: 8px;
}
.notification-filter .right-filter select {
    height: 34px;
    line-height: 34px;
    font-size: 13px;
    padding: 0 40px 0 15px;
    border-radius: 17px;
    width: 190px;
}
.notification-filter .right-filter .date-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.main-title-split {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    width: 100%;
}


@media (max-width: 1290px) {
    .notification-filter .right-filter .dateF {
        width: 150px;
    }
    .notification-filter .right-filter select {
        width: 150px;
    }
}
@media (max-width: 767px) {
    .widget-wrap.open.full-w {
        height: 100dvh;
        bottom: 0;
        width: 100dvw;
        left: 0;
        border-radius: 0;
    }
    .notification-filter .right-filter .dateF {
        width: 130px;
    }
    .notification-filter .search {
        width: 100%;
    }
    .notification-filter .search input {
        width: 100%;
    }
}
@media (min-width: 990px) {
    .widget-wrap .mid-sec .inner .split-wrap.split {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 60px;
    }
    .widget-wrap .mid-sec .inner .split-wrap.split .first {
        width: 50%;
    }
    .widget-wrap .mid-sec .inner .split-wrap.split .last {
        width: 50%;
    }
    .widget-wrap .mid-sec .inner .split-wrap.split .graph-1 {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
}
/* Widget footer: Start */
.widget-wrap .widget-footer {
    width: 100%;
}
.widget-wrap .widget-footer hr {
    border: 0;
    height: 4px;
    background: transparent;
    border-top: 0px solid #2b7c92;
    margin: 0 0 25px 0;
    opacity: 1;
    display: block;
}
.widget-wrap .widget-footer .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.widget-wrap .widget-footer .top .type-area {
    flex: 1;
    position: relative;
}
.widget-wrap .widget-footer .top .type-area input {
    width: 100%;
    height: 66px;
    border-radius: 33px;
    background: #fff;
    border: 0;
    outline: 0;
    padding: 0 70px 0 35px;
    font-size: 16px;
    color: #000;
}
.widget-wrap .widget-footer .top .type-area input::placeholder {
    color: #838591 !important;
    opacity: 1;
}
.widget-wrap .widget-footer .top .type-area .voice {
    background: #e7e7e7;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    outline: none;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.widget-wrap .widget-footer .top .type-area .voice svg {
    width: 20px;
}
.widget-wrap .widget-footer .top .type-area .voice svg path {
    fill: #7e838b; 
}
.widget-wrap .widget-footer .top .type-area .voice:hover {
    background: #82b85c;
}
.widget-wrap .widget-footer .top .type-area .voice:hover svg path {
    fill: #fff;
}
.widget-wrap .widget-footer .top .send:hover {
    background-image: linear-gradient(
  0deg,
  hsl(196deg 59% 42%) 0%,
  hsl(177deg 100% 32%) 31%,
  hsl(107deg 33% 55%) 100%
);
    border: 2px solid #81cf51;
}
.widget-wrap .widget-footer .top .send svg {
    width: auto;
    height: 23px;
    position: relative;
    right: -3px;
}
.widget-wrap .widget-footer .top .send svg path {
    fill: #318ca8 !important;
}
.widget-wrap .widget-footer .top .send:hover svg path {
    fill: #fff !important;
    
}
.widget-wrap .widget-footer .top .send {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: #fff;
    border: 0;
    outline: 0;
    border: 2px solid #fff;
}

/* History icon Start */
.widget-wrap .widget-footer .top .history {
    position: absolute;
    left: 15px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: none;
}
.widget-wrap .widget-footer .top .history svg {
    height: 26px;
    width: 26px;
}
.widget-wrap .widget-footer .top .history svg path {
    fill: #7e838b;
}
.widget-wrap .widget-footer .top .history:hover svg path {
    fill: #1ba0d8;
}
.widget-wrap .widget-footer .top .type-area.with-history input {
    padding-left: 55px;
}
.widget-wrap .widget-footer .top .type-area.with-history .history {
    display: flex;
}
.widget-wrap .widget-footer .selected-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}
.widget-wrap .widget-footer span {
    color: #fff;
    font-size: 16px;
}
.widget-wrap .widget-footer .selected-data .nice-select:after {
    display: none;
}
.widget-wrap .widget-footer .selected-data .nice-select .list {
    top: auto;
    bottom: 100%;
    margin: 0 0 10px 0;
    background: #308ba8;
    border-radius: 15px;
    border: 1px solid #21deff;
    border-bottom-left-radius: 0;
}
.widget-wrap .widget-footer .selected-data .nice-select {
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    line-height: 28px;
    border-radius: 15px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
}
.widget-wrap .widget-footer .selected-data .nice-select .option {
    font-weight: 400;
    color: #fff;
}
.widget-wrap .widget-footer .selected-data .nice-select .option.selected {
    color: #61ff00;
}
.widget-wrap .simplebar-scrollbar:before {
    background: #88e344;
}

/* History icon End */

/* History Modal : Start */
.history-modal {
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    bottom: 165px;
    z-index: 9;
    padding: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: 0.3s;
}
.history-modal.open {
    padding: 68px 20px 20px 20px;
    height: calc(100% - 165px);
}
.history-inside {
    border-radius: 20px;
    background: #fff;
    /* padding: 50px; */
    width: 100%;
    position: relative;
    padding: 20px;
    max-height: 100%;
}
.history-inside .chat-sec {
    /* background-color: red; */
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    margin-bottom: 15px;
    flex-direction: row;
}
.history-inside .chat-sec:last-child {
    margin-bottom: 0;
}
.history-inside .chat-sec .chat-image {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.history-inside .chat-sec .chat-image svg path {
    fill: #707070;
}
.history-inside .chat-sec .chat {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
}
.history-inside .chat-sec .chat .inside {
    padding: 15px;
    border-radius: 24px;
    border-top-right-radius: 4px;
    background: #1ba0d9;
    margin: 0 0 15px 0;
    text-align: right;
}
.history-inside .chat-sec .chat .inside:last-child {
    margin-bottom: 0;
}
.history-inside .chat-sec .chat .inside p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
}
.history-inside .chat-sec .chat .inside p:last-child {
    margin-bottom: 0;
}
.history-inside.inchat .chat-sec {
    width: 100% !important;
}
.history-inside.inchat .chat-sec .chat-image {
    background: #e0e0e0 !important;
}
.history-inside.inchat .chat-sec .chat {
    padding: 0 !important;
}











.history-modal .close-btn {
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 5px;
}
.history-modal .close-btn svg {
    width: 28px;
    height: 28px;
}
.history-modal .close-btn svg path {
    fill: #fff;
}
.history-modal .close-btn:hover svg path {
    fill: #81CF51;
}
/* History Modal : End */




/* Widget: End */

.reportClass {
    height: calc(100vh - 200px) !important;
}
.widgetEmbededClass {
    /* height: calc(250px) !important; */
}
.reportClass.mob {
    width: 360px;
    margin: 0 auto;
    border: 3px solid #eee;
    border-radius: 10px;
    overflow: hidden;
}


.audio-recorder {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 9;
    box-shadow: none !important;
    background-color: #d7d7d7 !important;
}
.audio-recorder.recording {
    background: #fff !important;
} 
.audio-recorder.recording span {
    color: #000;
}
.audioLoad {
    position: absolute;
    left: 33px;
    top: 27px;
    width: 40px;
}

.main-loader {
    width: 100%;
    background: #f7f7f7;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    position: relative;
    /* background: red; */
}
.main-loader .load-logo {
    width: 55px;
    height: 64px;
    /* background: red; */
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -32px;
    margin-left: -27.5px;
}
.main-loader .loader {
    border: 4px solid #e9e9e9;
    border-radius: 50%;
    border-top: 4px solid #7AB960;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.main-loader.fullSize {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.3);
}
.main-loader.fullSize .loader {
    border: 4px solid #ffffff;
    border-top: 4px solid #87ff55;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }