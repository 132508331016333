/* ImagePopup.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .popup-content img {
    max-width: 100%;
    max-height: 80vh;
  }
  
  .popup-content button {
    margin-top: 10px;
  }
  .popupButton {
    border: 0;
    padding: 0;
    margin: 0 !important;
    background: none;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #eee;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .popupButton svg {
    height: 12px;
  }
  .popupButton.download {
    right: 64px;
  }
  .popupButton.download svg {
    height: 14px;
  }
  .popupButton:hover {
    background: #1ba0d8;
  }
  .popupButton:hover svg path {
    fill: #ffffff !important;
  }