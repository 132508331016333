/* RemixDesign | woaichidapi@163.com | Redesigned by JimmyCheung */

.audioplayer {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 300px;
    height: auto;
    align-items: center;
    border: 0;
    border-radius: 0;
    background: none !important;
}
.audioplayer>audio {
    display: none;
}

.audioplayer-playpause {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause {
    background: #fff;
}

/* .audioplayer:not(.audioplayer-playing) .audioplayer-playpause:hover {
    background: rgba(91, 130, 255, 0.1);
} */

.audioplayer-playing .audioplayer-playpause {
    background: #fff;
    border: 1px solid #fff;
}

.audioplayer-playing .audioplayer-playpause:hover {
    background: #fff;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
    content: '';
    justify-content: center;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 6px solid transparent;
    border-right: none;
    border-bottom: 6px solid transparent;
    border-left: 10px solid #2577f5;
}

.audioplayer-playing .audioplayer-playpause a {
    content: '';
    display: flex;
    justify-content: space-between;
    width: 12px;
    height: 14px;
}

.audioplayer-playing .audioplayer-playpause a::before, .audioplayer-playing .audioplayer-playpause a::after {
    content: '';
    width: 4px;
    height: 14px;
    background-color: #2577f5;
}

.audioplayer-time {
    display: flex;
    width: 40px;
    justify-content:center;
    font-size: 12px;
    color: #fff;
}

.audioplayer-time-current {
    margin-left: 15px;
}

.audioplayer-time-duration {
    margin-right: 24px;
}

.audioplayer-bar {
    position: relative;
    display: flex;
    margin: 0 12px;
    height: 12px;
    flex-basis: 0;
    flex-grow: 1;
    cursor: pointer;
}

.audioplayer-bar::before {
    content: '';
    position: absolute;
    top: 5px;
    width: 100%;
    height: 2px;
    background-color: #DDE2E6;
}

.audioplayer-bar > div {
    position: absolute;
    left: 0;
    top: 5px;
}
.audioplayer-bar-loaded {
    z-index: 1;
    height: 2px;
    background: #BEC8D2;
}

.audioplayer-bar-played {
    flex-direction: row-reverse;
    z-index: 2;
    height: 2px;
    background: #15f788;
}

.audioplayer-bar-played::after {
    display: flex;
    position: absolute;
    content: '';
    box-sizing: border-box;
    top: -5px;
    right: -1px;
    margin-right: -5px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 6px;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-bar-played::after {
    border: 2px solid #fff;
}

.audioplayer-playing .audioplayer-bar-played::after {
    border: 2px solid #fff;

}

.audioplayer-volume {
    display: flex;
    align-items: center;
}

.audioplayer-volume-button {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.audioplayer-volume-button a {
    display: flex;
    width: 6px;
    height: 8px;
    background-color: #9A9FB0;
    position: relative;
}

.audioplayer-volume-button a:before, .audioplayer-volume-button a:after {
    content: '';
    position: absolute;
}

.audioplayer-volume-button a:before {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 9px solid #9A9FB0;
    border-bottom: 8px solid transparent;
    border-left: none;
    top: -4px;
}

.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
    left: 10px;
    top: -2px;
    width: 6px;
    height: 6px;
    border: 6px double #9A9FB0;
    border-width: 6px 6px 0 0;
    border-radius: 0 12px 0 0;
    transform: rotate(45deg);
}

.audioplayer-mute .audioplayer-volume-button a {
    background-color: #2577f5;
}

.audioplayer-mute .audioplayer-volume-button a:before {
    border-right: 9px solid #2577f5;
}

.audioplayer-volume-adjust {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.audioplayer-volume-adjust > div {
    position: relative;
    display: flex;
    width: 60px;
    height: 2px;
    cursor: pointer;
    background-color: #BEC8D2;
}

.audioplayer-volume-adjust div div {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background-color: #0059FF;
}

/* responsive | you can change the max-width value to match your theme */

@media screen and (max-width: 679px) {
    .audioplayer-volume-adjust {
        display: none;
    }
}

/* custom css  start */
.audioplayer-volume {
    display: none !important;
}
.audioplayer-time-duration {
    margin-right: 0 !important;
}
/* custom css  end */