
/*Client List page start*/
.title-top-wraper1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 25px;
}
.title-top-wraper1 h1{
    margin-bottom: 0 !important;
}
.grad-btn-1 {
    background-image: -moz-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border: 0;
    height: 30px;
    border-radius: 100px;
    padding: 0 20px;
}
.grad-btn-1:hover {
    background-image: -moz-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -webkit-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
    background-image: -ms-linear-gradient( 180deg, rgb(70,151,185) 0%, rgb(133,186,89) 100%);
}
.main-bg-wraper--1{
    background: #f1f5f6;
    padding: 28px 18px;
    border-radius: 10px;
}
.top--section1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top--section1 h4{
    font-size: 13px;
    line-height: 1.1;
    font-weight: 400;
    margin-bottom: 0;
}
.right-btns-row{
    display: inline-flex;
    gap: 5px;
}
.btngroup1{
    display: inline-flex;
    gap: 5px;
}
.button-new1{
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    border: 0;
    height: 35px;
    border-radius: 50px;
    padding: 0 20px;
}
.button-new1.blue{
    background-color: #1ba0d9;
}
.button-new1.blue:hover{
    background-color: #949494;
}
.button-new1.grey{
    background-color: #949494;
}
.button-new1.grey:hover{
    background-color: #1ba0d9;
}
.search-type-1 {
    position: relative;
}
.search-type-1 input {
    width: 177px;
    height: 35px;
    border: 1px solid #e2e2e2;
    border-radius: 30px;
    background: #fff;
    padding: 0 15px 0 36px;
    font-size: 13px;
    font-weight: 400;
    color: #000;
    outline: none !important;
}
.search-type-1 input::placeholder {
    color: #bbbcc2 !important;
    opacity: 1;
}
.search-type-1 img {
    position: absolute;
    top: 12px;
    left: 13px;
}
.listing-table-wraper{
    background: #fff;
    padding: 25px;
    border-radius: 15px;
    margin: 18px 0 0 0;
}
.table-style-new1{
    margin-bottom: 0;
}
.table-style-new1 thead{
    background-color: #f0f7ec;
}
.table-style-new1 thead th{
    color: #1ba0d8;
    font-weight: 600;
}
.table-style-new1 th, .table-style-new1 td{
    font-size: 14px;
    padding: 20px 20px;
    white-space: nowrap;
}
.table-style-new1 td{
    color: #838591;
}
.table-style-new1 td span{
    color: #000;
}
.table-style-new1 td a{
    color: #838591;
}
.table-style-new1 td a:hover{
    color: #1ba0d8;
}
.table-style-new1 tr:nth-child(even){
    background-color: #f4f6fb;
}
.action-icons-list {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;
}
.action-icons-list li{
    list-style: none;
    text-decoration: none;
}
.action-icons-list li a {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 1px solid #e1e3ea;
    background-color: #edf0f9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-icons-list a:hover {
    border: 1px solid #1ea1d9;
}
.action-icons-list .dropdown-menu{
    background-color: #1ba0d9;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 10px;
    right: -13px;
    left: auto;
    min-width: 175px;
    margin-top: 12px;
}
.action-icons-list .dropdown-menu::before{
    width: 10px;
  height: 10px;
  border-bottom: solid 10px #1ba0d9;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  position: absolute;
  top: -10px;
  right: 17px;
  content: "";
}

.action-icons-list .dropdown-menu a {
    width: auto;
    height: auto;
    border-radius: inherit;
    display: block;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #1a9bd2;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    padding: 12px 15px;
    border-radius: 0;
}
.action-icons-list .dropdown-menu a:hover{
    background-color:#1a9bd2;
    color: #fff;
}
.action-icons-list .dropdown-menu li:first-child a,
.action-icons-list .dropdown-menu a:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.action-icons-list .dropdown-menu li:last-child a,
.action-icons-list .dropdown-menu a:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 0;
}
.action-icons-list svg {
    width: 20px;
    color: #838591;
}

.action-icons-list svg path {
    fill: currentColor;
}

.action-icons-list li a:hover svg{
    color: #1ba0d9;
}
.action-icons-list .dropdown.with-dropdown button {
    padding: 0;
    border: 0;
    
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 1px solid #e1e3ea;
    background-color: #edf0f9;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    box-shadow: none !important;
}
.action-icons-list .dropdown.with-dropdown button:hover {
    border: 1px solid #1ea1d9;
}
.action-icons-list .dropdown.with-dropdown button:hover svg {
    color: #1ba0d9;
}
.action-icons-list .dropdown.with-dropdown button::after {
    display: none;
}
.action-icons-list .dropdown.with-dropdown .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    margin-right: -13px;
    height: 200px;
    overflow-y: auto;
}
.action-icons-list .dropdown.with-dropdown .dropdown-menu::before {
    right: 15px;
}
.min-h-400 {
    min-height: 400px;
}








.closebutton svg{
    display: none;
}
.closebutton .close-icon{
    display: block !important;
}
.closebutton .nav-icon1{
    display: none !important;
}

/*Responsive start*/
@media (max-width: 991px) {
    .top--section1{
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
}
@media (max-width: 575px) {
    .right-btns-row{
        flex-direction: column;
        width: 100%;
    }
    .search-type-1 input{
        width: 100%;
    }
}
/*Client List page end*/

/*Client creation page start*/
.title-top-wraper2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
}
.title-top-wraper2 h1 {
    margin-bottom: 0 !important;
}
.main-bg-wraper--2 {
    padding-bottom: 65px;
}
.details-list-wraper {
    border-top: 1px solid #d0d0d0;
    padding: 20px 0 30px;
}
.details-list-wraper h4{
    font-size: 16px;
    font-weight: 400;
    color: #1ba0d8;
    margin-bottom: 30px;
}

.form-style-1 input::-webkit-input-placeholder, .form-style-1 select::-webkit-input-placeholder {
    color: #101010 !important
}

.form-style-1 input::-moz-placeholder, .form-style-1 select::-moz-placeholder {
    color: #101010 !important
}

.form-style-1 input:-ms-input-placeholder, .form-style-1 select:-ms-input-placeholder {
    color: #101010 !important
}

.form-style-1 input:-moz-placeholder, .form-style-1 select:-moz-placeholder {
    color: #101010 !important
}

.form-style-1 textarea::-webkit-input-placeholder {
    color: #737373 !important
}

.form-style-1 textarea::-moz-placeholder {
    color: #101010 !important
}

.form-style-1 textarea:-ms-input-placeholder {
    color: #101010 !important
}

.form-style-1 textarea:-moz-placeholder {
    color: #101010 !important
}

.form-style-1 input {
    resize: none;
    padding: 13px 24px;
    font-size: 13px;
    color: #101010;
    font-weight: 400;
    background: #fff;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    border: 1px solid #909090;
}
.form-style-1 input[type="checkbox"] {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    cursor: pointer;
    height: 22px;
    width: 22px;
}

.form-style-1 input:focus {
    outline: 0;
    box-shadow: none;
    outline: 0 !important;
    color: #101010;
    border-color: #1ba0d8;
}
.form-style-1 label{
    color: #2b2a29;
    font-size: 14px;
    font-weight: 400;
}
.btngroup2 {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.btngroup2 .button-new1{
    height: 43px;
    font-size: 14px;
    padding: 0 28px;
    background: #949494;
    line-height: 43px;
}
.btngroup2 .grad-btn-1{
    height: 43px;
    font-size: 14px;
    padding: 0 35px;
    background: #1ba0d9;
}
.btngroup2 .grad-btn-1:hover,
.btngroup2 .button-new1:hover {
    background: #525050;
    color: #fff;
}




.title-top-right {}
.title-top-right > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: rgba(137, 137, 137, 0.9);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px 20px;
}
.title-top-right > ul > li > a {
    color: inherit;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 5px 5px;
    text-decoration: none !important;
}
.title-top-right > ul > li > a svg {
    flex-shrink: 0;
    flex-grow: 0;
    width: 15px;
}
.title-top-right > ul > li > a svg path {
    transition: 0.5s;
}
.title-top-right > ul > li > a:hover {
    color: #1ea1d9
}
.title-top-right > ul > li > a:hover svg path {
    fill: #1ea1d9;
}


/* dropdown-s1 :start */
.dropdown-s1 {}
.dropdown-s1 .dropdown-toggle::after {
    content: '\f107';
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    margin-left: 0;
    border: 0;
    width: auto;
    height: 12px;
    padding: 0;
    transform: none;
    position: relative;
    top: -3px;
}
.dropdown-s1 .dropdown-menu {
    width: 186px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 0px 0px;
    margin-top: 10px !important;
    box-shadow: 0px 0px 10px 0px rgba(32, 33, 36, 0.28);
    position: relative;
}
.dropdown-s1 .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -11px;
    right: 10px;
    width: 0; 
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #d0d0d0;
}
.dropdown-s1 .dropdown-menu::after {
    content: '';
    position: absolute;
    top: -9px;
    right: 11px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid #ffffff;
}
.dropdown-s1 .dropdown-menu .dropdown-item {
    font-size: 14px;
    font-weight: 300;
    color: rgba(100, 100, 100, 0.9);
    padding: 12px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.dropdown-s1 .dropdown-menu li:first-child .dropdown-item {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.dropdown-s1 .dropdown-menu li:last-child .dropdown-item {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dropdown-s1 .dropdown-menu .dropdown-item svg {
    width: 16px;
} 
.dropdown-s1 .dropdown-menu .dropdown-item svg path {
    transition: 0.5s;
}
.dropdown-s1 .dropdown-menu .dropdown-item:hover, 
.dropdown-s1 .dropdown-menu .dropdown-item.active {
    color: #ffffff;
    background-color: #1ea1d9
}
.dropdown-s1 .dropdown-menu .dropdown-item:hover svg path, 
.dropdown-s1 .dropdown-menu .dropdown-item.active svg path {
    fill: #ffffff;
}
/* dropdown-s1 :end */


/* modal-s1 :start */
.modal-s1 .modal-header {
    padding: 0 0 15px 0;
}
.modal-s1 .modal-header .modal-title  {
    font-size: 18px;
}
.modal-s1 span.label  {
    font-size: 12px;
    display: block;
    margin: 0 0 5px 0;
}
.modal-s1 .modal-inner {
    padding-top: 15px;
    padding-bottom: 15px;
}

.modal-s1 .modal-footer {
    padding-bottom: 0;
    padding-right: 0;
}
.modal-s1 .modal-footer .btn-1 {
    padding-left: 25px;
    padding-right: 25px;
    height: 30px;
    border-radius: 15px;
    font-size: 12px;
    color: #fff;
    margin: 0;
}
/* modal-s1 :end */


/* form-s1 :start */
.form-s1 .form-row {
    padding-bottom: 13px;
}
.form-s1 .form-row:last-child {
    padding-bottom: 0;
}
.form-s1 input[type="text"],
.form-s1 textarea {
    width: 100%;
    height: 36px;
    font-size: 12px;
    border-radius: 18px;
    border: 1px solid #b1b1b1;
    padding: 0 15px;
    outline: none;
}
.form-s1 textarea {
    height: 100px;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.form-s1 .nice-select {
    height: 36px;
    font-size: 12px;
    line-height: 36px;
    padding: 0 15px;
    border-radius: 18px;
}
.form-s1 .nice-select .list {
    width: 100%;
}
.form-s1 .nice-select .list li.focus {
    color: #000;
}
.form-s1 .nice-select .list li:hover {
    color: #fff;
    background: #1ba0d8 !important;
}
.form-s1 .form-check {
    display: flex;
    gap: 8px;
    margin-bottom: 5px;
}
.form-s1 .form-check .form-check-input {
    flex-shrink: 0;
    flex-grow: 0;
    width: 17px;
    height: 17px;
    background-color: #ffffff;
    border: 1px solid #b1b1b1;
    border-radius: 3px;
}
.form-s1 .form-check .form-check-input:checked[type=checkbox] {
    background-color: #7ab34a;
    border-color: #7ab34a;
}
.form-s1 .form-check .form-check-input:focus {
    box-shadow: none;
}
.form-s1 .form-check .form-check-label {
    font-size: 13px;
    color: #101010;
    line-height: 1.9;
}
/* form-s1 :end */


/* sidemenu-s1 :start */
.sidemenu-s1 {
    position: fixed;
    top: 55px;
    bottom: 0;
    right: -250px;
    width: 250px;
    height: calc(100% - 55px);
    background-color: #ffffff;
    border-left: 1px solid #d0d0d0;
    overflow: hidden auto;
    transition: 0.5s;
}
.sidemenu-s1.open {
    right: 0;
}
.sidemenu-s1 .smenu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 10px;
    position: relative;
}
.sidemenu-s1 .smenu-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #dee2e6;
}
.sidemenu-s1 .smenu-header h3 {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0;
}
.sidemenu-s1 .smenu-body {
    padding: 10px 0px;
}
.sidemenu-s1 .smenu-body .smblinks {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
}
.sidemenu-s1 .smenu-body .smblinks li {
    width: 100%;
}
.sidemenu-s1 .smenu-body .smblinks li a {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    color: rgba(77, 77, 76, 0.9);
    padding: 10px 10px;
}
.sidemenu-s1 .smenu-body .smblinks li a:hover,
.sidemenu-s1 .smenu-body .smblinks li a.active {
    color: #fff;
    background: #1ba0d8;
}
/* sidemenu-s1 :end */


/* notifications page :start */
.notifications-wrap {
    background-color: #f1f5f6;
    padding: 20px 20px;
    border-radius: 5px;
    overflow: hidden;
}
.notifications-wrap .notifwrap-block {
    background-color: #ffffff;
    padding: 15px 50px 15px 20px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #c2e6f5;
    position: relative;
}
.notifications-wrap .notifwrap-block .icn-read {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #bbbdbd;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notifications-wrap .notifwrap-block.read {
    background-color: #f4f7f8;
    border: 1px solid #e0e3e4;
}
.notifications-wrap .notifwrap-block .nwb-head {
    margin-bottom: 15px;
}
.notifications-wrap .notifwrap-block .nwb-head h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    color: #1ba0d8;
    margin-bottom: 0;
}
.notifications-wrap .notifwrap-block .nwb-head time {
    font-size: 12px;
    font-weight: 400;
    color: #9a9a9a;
}

.notifications-wrap .notifwrap-block .nwb-body {}
.notifications-wrap .notifwrap-block .nwb-body p {
    font-size: 14px;
    line-height: 1.6;
    color: #979797;
}
.notifications-wrap .notifwrap-block .nwb-body :last-child {
    margin-bottom: 0;
}

.notifications-wrap .notifwrap-block .nwb-attachments {}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block {
    width: 100%;
    max-width: 430px;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    padding: 15px 15px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    transition: 0.5s;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left {
    display: flex;
    align-items: center;
    gap: 10px 20px;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-icon {
    flex-grow: 0;
    flex-shrink: 0;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-icon img {
    max-width: 100%;
    height: auto;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-text h4 {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-text h5 {
    font-size: 14px;
    font-weight: 300;
    color: #979797;
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-right .nafilebtn-download {
    width: 143px;
    height: auto;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    background-color: #1ba0d8;
    border: 1px solid #1ba0d8;
    border-radius: 100px;
    padding: 8px 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
/* .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-right .nafilebtn-download:hover {
    color: #ffffff;
    background-color: #1595cb;
    border-color: #1595cb;
} */
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block:hover .nafb-inner {
    background-color: #efefef;
}



.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block {
    width: 215px;
    height: 155px;
    background-color: #052441;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block .naib-inner {
    width: 100%;
    height: 100%;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block .naib-inner::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #052441;
    z-index: 1;
    opacity: 0;
    transition: 0.5s;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block .naib-inner::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    background-image: url('../images/search-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 2;
    opacity: 0;
    transition: 0.5s;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block .naib-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block:hover .naib-inner::before {
    opacity: 0.5;
}
.notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block:hover .naib-inner::after {
    opacity: 1;
}
/* notifications page :end */

/* pagination-style-1 :start */
.pagination-style-1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.pagination-style-1 .pagination {
    gap: 10px;
    margin: 0;
}
.pagination-style-1 .pagination .page-item {
    width: 38px;
    height: 38px;
    text-align: center;
}
.pagination-style-1 .pagination .page-item .page-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #818181;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    overflow: hidden;
}
.pagination-style-1 .pagination .page-item:hover .page-link, 
.pagination-style-1 .pagination .page-item.active .page-link {
    color: #ffffff;
    background-color: #70b03b;
    border-color: #70b03b;
}
.pagination-style-1 .pagination .page-item.disabled {
    pointer-events: none;
}
/* pagination-style-1 :end */
.field-error {
    font-size: 13px;
    color: red;
    font-weight: 400;
    margin: 8px 0 0 15px;
    display: none;
}
.blank-field .field-error {
    display: block;
}

.main-wrap .right .inner.fh1 {
    height: calc(100vh - 54px);
}

.main-bg-wraper--2.fh2 {
    height: calc(100% - 86px);
    padding: 0;
}

.main-bg-wraper--2.fh2 .card-body {
    height: 100%;
}   
.info-text {   
    font-size: 13px;    
    font-weight: 400;
    margin: 8px 0 0 15px;
}


.type-area:has(.audio-recorder.recording) > input {
    font-size: 0 !important;
}
.audioClose {
    background: url('data:image/svg+xml,%3Csvg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M15.3394 8.32245C15.7434 7.94589 15.7657 7.31312 15.3891 6.90911C15.0126 6.50509 14.3798 6.48283 13.9758 6.85938L11.0497 9.5866L8.32245 6.66048C7.94589 6.25647 7.31312 6.23421 6.90911 6.61076C6.50509 6.98731 6.48283 7.62008 6.85938 8.0241L9.5866 10.9502L6.66048 13.6775C6.25647 14.054 6.23421 14.6868 6.61076 15.0908C6.98731 15.4948 7.62008 15.5171 8.0241 15.1405L10.9502 12.4133L13.6775 15.3394C14.054 15.7434 14.6868 15.7657 15.0908 15.3891C15.4948 15.0126 15.5171 14.3798 15.1405 13.9758L12.4133 11.0497L15.3394 8.32245Z" fill="%23646464"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20Z" fill="%23646464"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
}
.audioClose:hover {
    background: url('data:image/svg+xml,%3Csvg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M15.3394 8.32245C15.7434 7.94589 15.7657 7.31312 15.3891 6.90911C15.0126 6.50509 14.3798 6.48283 13.9758 6.85938L11.0497 9.5866L8.32245 6.66048C7.94589 6.25647 7.31312 6.23421 6.90911 6.61076C6.50509 6.98731 6.48283 7.62008 6.85938 8.0241L9.5866 10.9502L6.66048 13.6775C6.25647 14.054 6.23421 14.6868 6.61076 15.0908C6.98731 15.4948 7.62008 15.5171 8.0241 15.1405L10.9502 12.4133L13.6775 15.3394C14.054 15.7434 14.6868 15.7657 15.0908 15.3891C15.4948 15.0126 15.5171 14.3798 15.1405 13.9758L12.4133 11.0497L15.3394 8.32245Z" fill="%23FF0000"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20Z" fill="%23FF0000"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
.type-area:has(.audio-recorder.recording) .audioClose {
    display: block;
}
.audioClose.out {
    left: 10px;
    display: block;
}