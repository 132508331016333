@media (max-width: 1399px) {}
@media (max-width: 1310px) {
    .main-wrap .right .inner.fh1 {
        height: calc(100vh - 90px);
    }
    .reportClass {
        height: calc(100vh - 243px) !important;
    }
}

@media (max-width: 1199px) {
    .main-wrap .right .top {
        height: auto;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .main-wrap .right .top .cell-one {
        width: 100%;
        justify-content: space-between;
    }
    .main-wrap .right .top .cell-two {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
    }
    .clients-tab-inner .accordion-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .accordion-actions {
        gap: 30px;
        padding-left: 40px;
        padding-bottom: 15px;
    }
    .client-accordion-inner1-main.inner-accordion .accordion-actions {
        padding-left: 60px;
    }
    .client-accordion-inner1-main.inner-accordion.in1 .accordion-actions {
        padding-left: 80px;        
    }
}

@media (max-width: 991px) {
    .main-wrap .right .top .search input {
        width: 160px;
    }
    .main-wrap .right .top ul.menu {
        margin-left: 10px;
    }
    .main-wrap .right .top ul.menu li a {
        padding-left: 12px;
        padding-right: 12px;
    }
    .accordion-actions {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
    .accordion-actions .buttons {
        gap: 10px;
    }
}

@media (max-width: 767px) {
    .main-wrap .left {
        position: absolute;
        background: #fff;
        z-index: 9;
    }
    .main-wrap .right {
        padding-left: 55px;
        width: calc(100% - 55px);
    }
    .clients-tab-inner {
        padding: 15px;
    }
    /* Menu Collaps: Start */
    /* .main-wrap .left {
        width: 55px;
    } */
    .main-wrap .left .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 0;
    }
    .main-wrap .left .breakpoint-on .dd-trigger {
        display: none;
    }
    .main-wrap .left .breakpoint-on .dd-trigger {
        display: none;
    }
    .main-wrap .left .classy-nav-container .classy-navbar .classynav ul li a img {
        margin-right: 4px;
    }
    /* .main-wrap .left .classy-nav-container .classy-navbar .classynav ul.dropdown {
        display: none !important;
    } */
    .main-wrap .left .s-logo {
        display: none !important;
    }
    /* When open */
    .main-wrap .left.off {
        width: 230px;
    }
    .main-wrap .left.off .classy-nav-container .classy-navbar .classynav ul li a {
        font-size: 14px;
    }
    .main-wrap .left.off .breakpoint-on .dd-trigger {
        display: block;
    }
    .main-wrap .left.off .breakpoint-on .dd-trigger {
        display: block;
    }
    .main-wrap .left.off .classy-nav-container .classy-navbar .classynav ul li a img {
        margin-right: 15px;
    }
    /* .main-wrap .left.off .classy-nav-container .classy-navbar .classynav ul.dropdown {
        display: block !important;
    } */
    .main-wrap .left.off .s-logo {
        display: block !important;
    }
    /* Menu Collaps: End */

    /* Widget Responsive: Start */
    .widget-wrap {
        z-index: 9;
        width: calc(100% - 40px);
    }
    .audioplayer {
        width: 200px;
    }
    /* Widget Responsive: End */

    .title-top-right > ul > li > a {
        font-size: 0;
        gap: 0;
    }
    .title-top-right .dropdown .dropdown-menu.show {
        left: 0;
        right: auto;
    }
    .dropdown-s1 .dropdown-menu::before {
        right: auto;
        left: 10px;
    }
    .dropdown-s1 .dropdown-menu::after {
        left: 11px;
        right: auto;
    }

}

@media (max-width: 575px) {
    .main-wrap .right .top .cell-one {
        flex-direction: column;
        align-items: flex-start;
    }
    .main-wrap .right .top .search {
        float: none;
        width: 100%;
    }
    .main-wrap .right .top .search input {
        width: 100%;
    }
    .main-wrap .right .top ul.menu {
        margin-left: 0;
        margin-top: 15px;
    }
    .clients-sec .clients-top {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    .clients-sec .clients-top .filter-cell {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 100%;
    }
    .clients-sec .clients-top .search {
        width: 100%;
    }
    .clients-sec .clients-top .search input {
        width: 100%;
    }
    .clients-sec .clients-top .filter-cell .nice-select {
        width: 100%;
    }


    .update-data {
        position: relative;
        margin-bottom: 10px;
        top: 0;
        left: 0;
    }


    /* notifications page :start */
    .notifications-wrap .notifwrap-block .nwb-head h3 {
        font-size: 16px;
    }
    .notifications-wrap .notifwrap-block .nwb-head time {
        font-size: 11px;
    }
    .notifications-wrap .notifwrap-block .nwb-body p {
        font-size: 13px;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left {
        gap: 10px 10px;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-icon img {
        max-width: 24px;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-text h4 {
        font-size: 16px;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-left .nafbl-text h5 {
        font-size: 12px;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-right .nafilebtn-download {
        width: auto;
        height: auto;
        padding: 10px 10px;
        aspect-ratio: 1/1;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-right .nafilebtn-download span {
        display: none;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-files .nafile-block .nafb-inner .nafileblock-right .nafilebtn-download img {
        max-width: 15px;
        height: auto;
    }
    .notifications-wrap .notifwrap-block .nwb-attachments .notifattach-images .naimage-block {
        width: 100%;
    }
    /* notifications page :end */

    /* Widget: Start */
    .widget-wrap .mid-sec .inner .chat-sec .chat-image {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
    }
    .widget-wrap .mid-sec .inner .chat-sec .chat-image img {
        width: 20px !important;
    }
    .widget-wrap .mid-sec .inner .chat-sec {
        gap: 10px;
    }
    .widget-wrap .mid-sec .inner .chat-sec .chat {
        border-radius: 10px;
        border-top-left-radius: 4px;
    }
    .widget-wrap .mid-sec .inner .chat-sec {
        width: 100%;
    }
    .widget-wrap .mid-sec .inner .chat-sec .chat .buttons .btn {
        font-size: 12px;
    }
    .widget-wrap {
        border-radius: 20px;
    }
    .widget-wrap .widget-footer .top .type-area input {
        height: 40px;
        border-radius: 20px;
    }
    .widget-wrap .widget-footer .top button.send {
        width: 40px;
        height: 40px;
    }
    .widget-wrap .widget-footer .top {
        gap: 10px;
    }
    .widget-wrap .widget-footer hr {
        margin-bottom: 15px;
    }
    .widget-wrap .widget-footer .top .send svg {
        height: 18px;
        right: -2px;
    }
    .widget-wrap .widget-footer .top .type-area .voice {
        width: 30px;
        height: 30px;
        top: 5px;
        right: 5px;
    }
    .widget-wrap .widget-footer .top .type-area .voice svg {
        width: 13px;
    }
    .widget-wrap.open {
        padding: 15px;
    }
    .widget-wrap .widget-footer .top .type-area input {
        font-size: 14px;
    }
    .widget-wrap .widget-footer .top .history svg {
        height: 22px;
        width: 22px;
    }
    .widget-wrap .widget-footer .top .type-area.with-history input {
        padding-left: 45px;
    }
    .history-modal {
        bottom: 120px;
    }
    .history-modal.open {
        height: calc(100% - 120px);
    }
    /* Widget: End */

    .audio-recorder {
        border-radius: 14px !important;
        width: 28px !important;
        right: 8px !important;
        left: auto !important;
        top: 6px;
    }
    .audio-recorder img {
        padding: 6px !important;
    }
    .audio-recorder.recording {
        width: 230px !important;
    }
    .audioLoad {
        top: 15px;
    }

}