body {
    background: #fff !important;
    overflow-y: auto !important;
}
.sidebar {
    max-width: 100% !important;
    min-width: 100% !important;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
}
.navbar-expand-lg .navbar-nav .nav-item.dropdown .dropdown-menu {
    position: relative !important;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0;
}
.navbar-expand-lg .navbar-nav .nav-item.dropdown .dropdown-menu.show {
    background: #f1f5f6;
}
.navbar-expand-lg .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item {
    padding: 10px 15px 10px 50px;
    font-size: 14px;
}
.navbar-expand-lg .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
    background: none !important;
    color: #1ba0d8 !important;
}
.navbar-toggler {
    display: none;
}

.main-wrap .left .bg-body-tertiary {
    padding: 0 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background: #fff !important;
}
.main-wrap .left .bg-body-tertiary .container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.main-wrap .left .bg-body-tertiary .container .navbar-collapse {
    width: 100%;
}
.main-wrap .left .navbar-nav {
    width: 100%;
}
.main-wrap .left .navbar-nav a.nav-link {
    margin: 0;
    padding: 15px 15px;
    font-size: 0;
    color: #000;
    font-weight: 400;
    position: relative;
    width: 100%;
    display: block;
    transition: 0.3s;
}
.main-wrap .left .navbar-nav a.nav-link.active,
.main-wrap .left .navbar-nav a.nav-link:hover {
    color: #1ba0d8;
}
.main-wrap .left .navbar-nav a.nav-link::after {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) rotate(45deg);
    display: none;
}
.main-wrap .left .navbar-nav a.nav-link[aria-expanded="true"] {
    background: #f1f5f6;
    color: #1ba0d8;
}
.main-wrap .left.off .navbar-nav a.nav-link::after {
    display: none;
}
.main-wrap .left.off .navbar-nav a.nav-link {
    font-size: 0;
}
@media (min-width: 767px) {
    .main-wrap .left .navbar-nav a.nav-link {
        font-size: 14px;
    }
    .main-wrap .left .navbar-nav a.nav-link::after {
        display: block;
    }
}
@media (max-width: 767px) {
    .main-wrap .left.off .navbar-nav a.nav-link {
        font-size: 14px;
    }
    .main-wrap .left.off .navbar-nav a.nav-link::after {
        display: block;
    }
}
.menu-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.menu-cover img {
    margin-right: 15px;
}
.menu-cover svg {
    margin-right: 15px;
    min-width: 18px;
    min-height: 18px;
}
.main-wrap .left .navbar-nav a.nav-link.active svg path,
.main-wrap .left .navbar-nav a.nav-link.show svg path,
.main-wrap .left .navbar-nav a.nav-link:hover svg path {
    fill: #1ba0d8;
}
.mobBurggerButton {
    display: none;
}
/* Accordion: Start */
.clients-sec .accordion {
    margin-top: 20px;
}
.clients-sec .accordion .card {
    margin: 0 0 20px 0;
    box-shadow: none;
}
.clients-accordion-main .card {
    margin: 20px 0 0 0;
}
.clients-accordion-main .card .card-header {
    margin: 0 0 0 0;
    border: 0;
    outline: none;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebf0f1 !important;
}
.clients-accordion-main .card .card-header button {
    box-shadow: none !important;
    padding: 20px 15px;
    background-color: #f1f5f6 !important;
    font-size: 16px;
    font-weight: 400;
    color: #1ba0d8;
    border-radius: 5px !important;
    width: 100%;
    border: 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 !important;
}
.clients-accordion-main .card .card-header button img {
    width: 14px;
    height: 8px;
}
.clients-accordion-main .card-body {
    padding: 15px;
    background-color: #f1f5f6 !important;
}
.clients-accordion-main .card-body ul.nav-tabs .nav-item button {
    margin: 0 !important;
    padding: 0;
    border: 0;
    border-radius: 0;

    font-size: 14px;
    font-weight: 400;
    color: #2b2a29;
    padding: 8px 18px;
    border: 0;
    border-radius: 0;
    background: none;
}
.clients-accordion-main .card-body ul.nav-tabs .nav-item button[aria-selected="true"] {
    border-bottom: 2px solid #1ba0d8;   
}
.accordion-actions {
    padding-right: 15px;
}


.clients-tab-inner .accordion {
    margin-top: 0;
}
.clients-tab-inner .accordion .card {
    margin-bottom: 0 !important;
}
.clients-tab-inner .accordion .card:nth-of-type(even) .card-header {
    background-color: #fff !important;
}
.clients-tab-inner .accordion .card:nth-of-type(even) .card-header button {
    background: none !important   ;
}
.clients-tab-inner .card-header {
    background-color: #f1f5f6 !important;
}
.clients-tab-inner .card .card-header button {
    font-size: 14px;
    word-break: break-all;
    background: #ebf0f1 !important;
    justify-content: flex-start;
    color: #838591;
}
.clients-tab-inner .card .card-header button svg {
    height: 16px;
    width: 22px;
    margin-right: 10px;
}
.clients-tab-inner .card .card-header button svg path {
    fill: #838591;
}
.clients-accordion-main .card-body .page-accordion-wrap {
    /* background-color: red !important; */
    padding: 0 0 0 12px;
}
.clients-accordion-main .card-body .page-accordion-wrap .card-header {
    background-color: #f7fafb !important;
}
.clients-accordion-main .card-body .page-accordion-wrap .card-header button {
    background-color: #f7fafb !important;
}
.clients-accordion-main .card-body .page-accordion-wrap .accordion .card:nth-of-type(even) .card-header {
    background-color: #f7fafb !important;
}
.visual-accordion-wrap {
    padding: 0 !important;
}
.clients-accordion-main .card-body .visual-accordion-wrap .card-header {
    background: #f0f7f9 !important;
}
.clients-accordion-main .card-body .visual-accordion-wrap .card-header button {
    background: #f0f7f9 !important;
}
.clients-accordion-main .card-body .visual-accordion-wrap .accordion .card:nth-of-type(even) .card-header {
    background: #f0f7f9 !important;
}

/* .page-accordion-wrap .card-header {
    padding-left: 20px !important;
}
.visual-accordion-wrap .card-header {
    padding-left: 40px !important;
} */

.clients-tab-inner {
    padding: 0;
    border-radius: 0;
    background: none;
}

/* Accordion: End */
/* Top slider : Start */
.react-multiple-carousel__arrow {
    padding: 0;
}
.react-multiple-carousel__arrow--left {
    /* background: red !important; */
    left: auto !important;
    position: absolute;
    top: 20px;
    right: 28px;

    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;

    background: none !important;
    background-image: url(../images/arrow-left.svg) !important;
    background-position: center center;
    background-repeat: no-repeat;
}
.react-multiple-carousel__arrow--left::before {
    display: none !important;
}
.react-multiple-carousel__arrow--right {
    /* background: red !important; */
    position: absolute;
    left: auto !important;
    top: 20px;
    right: 0 !important;

    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;

    background: none !important;
    background-image: url(../images/arrow-right.svg) !important;
    background-position: center center;
    background-repeat: no-repeat;
}
.react-multiple-carousel__arrow--right::before {
    display: none !important;
}
/* Top slider : End */

.loginUser {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 9999;
    cursor: pointer;
}
.loginUser img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
}
.loginUser img.arrow {
    width: 11px;
    height: 7px;
    margin-left: 8px;
}
.loginUser .dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    background: #1ba0d8;
    border: 0;
    border-radius: 0;
}
.loginUser .dropdown-menu.show {
    display: block !important;
}
.main-wrap .right .top .cell-one .nice-select {
    display: none;
}
.loginUser li.name {
    display: none;
}

/* Loader: Start */
.acc-loader {
    padding: 40px;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.acc-loader svg {
    width: 35px;
    height: 35px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
.acc-loader.fullPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.35);
}
.acc-loader.fullPage svg path {
    fill: #fff;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
/* Loader: End */

.title-top-right .dropdown {
    position: relative !important;
}
.title-top-right .dropdown .dropdown-menu.show {
    position: absolute;
    right: 0;
    top: 100%;
}
.btnstyle1 {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    border: 0;
    height: 35px;
    line-height: 35px;
    border-radius: 50px;
    padding: 0 20px;
    background: #1ba0d9;
}
.btnstyle1:hover {
    background: #949494;
}
.selectStyle1 {
    border: 1px solid #909090;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 23px;
    font-size: 13px;
    cursor: pointer;
}

/* Middle section full screen : Start */
.full-screen-row {
    padding: 0 0 15px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 25px;
}
.full-screen-row a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #000;
    font-size: 14px;
}
.full-screen-row a:hover {
    color: #1ba0d8;
}
.full-screen-row a svg {
    width: 20px;
    height: 20px;
}
.full-screen-row a:hover svg path {
    fill: #1ba0d8;
}
.main-wrap .left.center-full {
    margin-left: -230px;
}
.main-wrap .right.center-full {
    width: 100%;
	padding-left: 0;
}
.main-wrap .right.center-full .top {
    margin-top: -54px;
}
/* Middle section full screen : End */











































@media (max-width: 1199px) {
    .clients-accordion-main .card .card-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .accordion-actions {
        padding-left: 15px;
    }
}
@media (max-width: 991px) {
    .navbar {
        width: 100% !important;
    }
    .navbar-collapse.collapse {
        display: block !important;
    }
}
@media (max-width: 767px) {
    .main-wrap .left .navbar-nav a.nav-link {
        font-size: 14px;
    }
    .main-wrap .left .s-logo {
        display: block !important;
    }
    .main-wrap .left .navbar-nav a.nav-link::after {
        display: block !important;
    }
    .main-wrap .right.off,
    .main-wrap .right {
        padding-left: 0;
        width: 100%;
    }
    .main-wrap .right .top .search {
        display: none;
    }
    .main-wrap .left.off {
        left: 0;
    }
    .main-wrap .left {
        left: -230px;
        transition: 0.5s;
    }
    .main-wrap .left.pull {
        left: 0;
    }
    .main-wrap .left.pull.off {
        left: -230px;
    }
    .mobBurggerButton {
        display: block;
        border: 0;
        background: none;
        outline: none;
        margin-right: 15px;
    }
    .main-wrap .right .top .cell-one .nice-select {
        width: auto;
        padding: 0;
        border: 0;
        display: table;
        background: none !important;
        color: #fff;
        margin: 0 15px 0 0;
    }
    .main-wrap .right .top .cell-one .nice-select option {
        background: #108abd;
    }
    .main-wrap .right .top .cell-one {
        width: auto;
        flex-direction: row;
        align-items: center;
    }
    .main-wrap .right .top {
        flex-direction: row;
        align-items: center;
    }
    .main-wrap .right .top .cell-two {
        margin-top: 0;
        justify-content: flex-end;
    }
    .main-wrap .right .top ul.menu {
        display: none;
    }
    .loginUser span {
        display: none;
    }
    .loginUser img.arrow {
        display: none;
    }
    .loginUser li.name {
        display: block;
        font-size: 15px;
        color: #fff;
        padding: 5px 10px;
        background: #00b1fb;
        font-weight: 700;
    }
    .loginUser img {
        margin-right: 0;
    }
}
@media (max-width: 575px) {
    .main-wrap .right .inner {
        padding: 15px 5px 10px 5px;
    }
    .clients-accordion-main .card-body {
        padding: 5px;
    }
    .clients-tab-inner {
        padding: 10px;
    }
    .top-slider {
        margin-bottom: 20px;
    }
    .clients-accordion-main .card .card-header button {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .clients-sec .accordion .card {
        margin-bottom: 10px;
    }
    .accordion-actions {
        gap: 10px;
    }
}